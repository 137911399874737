export const MobileLeftNav = {
  init: function() {
    this.initMobileLeftNav();
    this.initSkipNav();
  },
  initMobileLeftNav: () => {
    const leftNav = document.querySelector('.mobile-dropdown'),
    dropdown = document.querySelector('.dropdown.temp ul'),
    width = document.body.clientWidth,
    subMenu = document.getElementById('subMenu');

    if (leftNav && width <= '991') {
      dropdown.classList.add('dropdown-menu', 'dropdown-menu-mobile');
      subMenu.style.display = 'block';
    } else {
      if (location.pathname != '/') {
        $('#subMenu li a[href^="/' + location.pathname.split("/")[1] + '"]').addClass('active');
      }
    }
  },
  initSkipNav: () => {
    const skipLeftNav = document.querySelector('.skip-nav');
    if (skipLeftNav) {
      skipLeftNav.addEventListener('click', () => {
        document.querySelector('.left-nav').setAttribute('tabindex', '-1');
        document.getElementById('article').focus();
      })
    }
  }
}