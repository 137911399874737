export const Slider = {
  init: function() {
    this.initHeroSlider();
    this.initFactsSlider();
    this.initQuickLinksSlider();
    this.initSectionalSlider();
    this.hideArrowsHeroSlider();
  },
  initHeroSlider: () => {
    if ($('.header-slider').length) {
      $('.header-slider').slick({
        arrows: false,
        dots: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        appendDots: '#dots-container .container'
      });
      $('.header-slider--prev').each(function() {
        $(this).on('click', function() {
          $('.header-slider').slick('slickPrev');
        });
      });
      $('.header-slider--next').each(function() {
        $(this).on('click', function() {
          $('.header-slider').slick('slickNext');
        });
      });
      $('.pause').on('click', function() {
        $('.header-slider').slick('slickPause');
      });
      $('.play').on('click', function() {
        $('.header-slider').slick('slickPlay');
      });
      /* Accessibility Labels for Sliders */
      $('.header-slider .slick-track').attr('aria-label', 'Homepage Slider');
    }
  },
  initFactsSlider: () => {
    if ($('.facts-slider').length) {
      $('.facts-slider').slick({
        arrows: false,
        draggable: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
      });
      $('.btn-slider-facts-prev').each(function() {
        $(this).on('click', function() {
          $('.facts-slider').slick('slickPrev');
        });
      });
      $('.btn-slider-facts-next').each(function() {
        $(this).on('click', function() {
          $('.facts-slider').slick('slickNext');
        });
      });
      /* Accessibility Labels for Sliders */
      $('.facts-slider .slick-track').attr('aria-label', 'Florida Department of Education Facts Slider');
    }
  },
  initQuickLinksSlider: () => {
    if ($('.quick-links-slider').length) {
      $('.quick-links-slider').slick({
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
      });
      $('.btn-slider-quick-links-prev').each(function() {
        $(this).on('click', function() {
          $('.quick-links-slider').slick('slickPrev');
        });
      });
      $('.btn-slider-quick-links-next').each(function() {
        $(this).on('click', function() {
          $('.quick-links-slider').slick('slickNext');
        });
      });
      /* Accessibility Labels for Sliders */
      $('.quick-links-slider .slick-track').attr('aria-label', 'Florida Department of Education Quick Links Slider');
    }
  },
  initSectionalSlider: () => {
    if ($('.sectional-slider').length) {
      $('.sectional-slider').slick({
        arrows: false,
        dots: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        appendDots: '#dots-container .container'
      });
      $('.sectional-slider--prev').each(function() {
        $(this).on('click', function() {
          $('.sectional-slider').slick('slickPrev');
        });
      });
      $('.sectional-slider--next').each(function() {
        $(this).on('click', function() {
          $('.sectional-slider').slick('slickNext');
        });
      });
      $('.pause').on('click', function() {
        $('.sectional-slider').slick('slickPause');
      });
      $('.play').on('click', function() {
        $('.sectional-slider').slick('slickPlay');
      });
      /* Accessibility Labels for Sliders */
      $('.sectional-slider .slick-track').attr('aria-label', 'Sectional Slider');
    }
  },
  hideArrowsHeroSlider: () => {
    const slider = document.querySelectorAll('.header-slider .item');

    if (slider.length == 1) {
      slider.forEach(slides => {
        slides.querySelector('.controls').classList.add('d-none');
        slides.querySelector('.control-arrows').classList.add('d-none');
      });
    }
  },
}