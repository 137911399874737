export const Nav = {
  init: function() {
    this.fixedNav();
    this.dropdownMenus();
  },
  fixedNav: () => {
    const nav = document.getElementById('fixed-nav');
    
    if (nav) {
      const navOffsetTop = nav.offsetTop;
      let previousScroll = window.scrollY;
      
      window.addEventListener('scroll', () => {
        const currentScroll = window.scrollY;
        if ( currentScroll > navOffsetTop ) {
          nav.classList.add('sticky', 'shadow-sm');
        } else {
          nav.classList.remove('sticky', 'shadow-sm');
        }
        previousScroll = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
      });
    }
  },
  dropdownMenus: () => {
    const dropdownBtns = document.querySelectorAll('.dropdown-btn'),
    dropdowns = document.querySelectorAll('nav .dropdown'),
    dropdownMenus = document.querySelectorAll('.dropdown-menu'),
    menuFeatured = document.querySelector('#featured-dropdown .dropdown-featured'),
    menuDivisions = document.querySelector('#divisions-dropdown .dropdown-divisions');

    document.addEventListener('click', (event) => {
      const isClickedOutside = document.contains(event.target);
      dropdowns.forEach(dropdown => {
        const isDropdownClicked = dropdown.contains(event.target),
        allDropdownMenus = document.querySelectorAll('nav .dropdown .dropdown-menu'),
        innerDropdownMenu = dropdown.querySelector('.dropdown-menu');

        if (isClickedOutside != isDropdownClicked) {
          innerDropdownMenu.classList.remove('expanded');
          // allDropdownMenus.forEach(menu => {
          //   if (menu !== innerDropdownMenu) {
          //     console.log(menu);
          //     menu.classList.remove('expanded');
          //   }
          // });
        }
      });
    });

    document.addEventListener('keyup', (event) => {
      event.preventDefault;
      const key = event.key || event.keyCode;
      if (dropdownBtns.length && (key === 'Escape' || key === 'Esc' || key === 27)) {
        dropdownMenus.forEach(dropdownMenu => {
          if (dropdownMenu.classList.contains('open')) {
            dropdownMenu.classList.remove('open');
          }
        });
      }
    });
    if (dropdowns.length) {
      dropdowns.forEach(dropdown => {

        var menuExplore = document.querySelector('#explore-dropdown .dropdown-explore'),
        menuDivisionsOut = document.getElementById('dropdownMenuDivisions'),
        menuExploreOut = document.getElementById('dropdownMenuExplore'),
        innerDropdownMenu = dropdown.querySelector('.dropdown-menu');
    
        dropdown.addEventListener('click', (event) => {
          event.preventDefault;
          const allDropdownMenus = document.querySelectorAll('nav .dropdown .dropdown-menu');

          allDropdownMenus.forEach(menu => {
            if (menu !== innerDropdownMenu) {
              menu.classList.remove('expanded');
            }
          });

          const key = event.key || event.keyCode;
          if (innerDropdownMenu) {
            innerDropdownMenu.classList.toggle('expanded');

            if (key === 'Escape' || key === 'Esc' || key === 27) {
              innerDropdownMenu.classList.remove('expanded');
            }
          }
        });

      
        dropdown.addEventListener('keyup', (event) => {
          event.preventDefault;

          var key = event.key || event.keyCode;

          if (innerDropdownMenu) {
            event.target.addEventListener('mouseover', () => {
              innerDropdownMenu.classList.remove('open');
            });
            if (key === 13 || key === 'Enter') {
              innerDropdownMenu.classList.toggle('expanded');
              dropdown.classList.toggle('active');
            }
            if (key === 'Escape' || key === 'Esc' || key === 27) {
              innerDropdownMenu.classList.remove('expanded');
            }
          }
          if (event.target.id === 'close-divisions' && menuDivisions.classList.contains('expanded')) {
            menuDivisionsOut.focus();
          }
          if (event.target.id === 'close-explore' && menuExplore.classList.contains('expanded')) {
            menuExploreOut.focus();
          }
          if (event.target.id === 'close-featured' && menuFeatured.classList.contains('expanded')) {
            menuFeatured.classList.remove('expanded');
          }
          if (event.target.id === 'explore-dropdown' && menuDivisions.classList.contains('expanded')) {
            menuDivisions.classList.remove('expanded');
          }
          if (event.target.id === 'featured-dropdown' && menuExplore.classList.contains('expanded') || event.target.id === 'divisions-dropdown' && menuExplore.classList.contains('expanded')) {
            menuExplore.classList.remove('expanded');
          }
          if (event.target.id === 'explore-dropdown' && menuFeatured.classList.contains('expanded')) {
            menuFeatured.classList.remove('expanded');
          }

        });
        dropdown.addEventListener('keydown', (event) => {
          event.preventDefault;
          if (event.target.id === 'close-divisions') {
            document.querySelector('.dropdown-divisions').classList.toggle('open');
            menuDivisionsOut.focus();
          }
          if (event.target.id === 'close-explore') {
            document.querySelector('.dropdown-explore').classList.toggle('open');
          }
          if (event.target.id === 'close-featured') {
            document.querySelector('.dropdown-featured').classList.toggle('open');
          }
        });

        dropdown.addEventListener('mouseover', (event) => {
          innerDropdownMenu.classList.add('open');
        });
        dropdown.addEventListener('mouseout', () => {
          innerDropdownMenu.classList.remove('open');
        });
      });
    }
  },
}