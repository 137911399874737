export const Utils = {
  init: function() {
    const currentTrail = document.querySelector('.breadcrumb .fileTrailCurrent');
    if ($('[data-background]')) {
      this.setDataBackgrounds();
    }
    this.setDataURLs();

    if (currentTrail) {
      this.currentTrail = currentTrail;
      this.cutCurrentTrail();
    }
  },
  setDataBackgrounds: () => {
    $('[data-background]').each(function() {
      var attr, that;
      that = $(this);
      attr = that.attr('data-background');
      that.css('background-image', 'url(' + attr + ')');
    });
  },
  setDataURLs() {
    if ($('[data-url-external]')) {
      $('[data-url-external]').each(function() {
        var url, that;
        that = $(this);
        url = that.attr('data-url-external');
        that.on('click', function() {
          window.open(url, '_blank');
        });
      });
    }
    if ($('[data-url]')) {
      $('[data-url]').each(function() {
        var url, that;
        that = $(this);
        url = that.attr('data-url');
        that.on('click', function() {
          window.location = url;
        });
      });
    }
  },
  cutCurrentTrail() {
    $('.breadcrumb .fileTrailCurrent').text(cut(30));

    function cut(n) {
      return function truncate(i, str) {
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
      };
    }
  },
}