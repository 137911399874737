export const MobileMenu = {
  init: function() {
    this.initMobileNav();
  },
  initMobileNav: () => {
    const hamburger = document.getElementById('hamburger-menu'),
    toggle = document.querySelector('.toggle-nav'),
    breadcrumb = document.querySelector('.breadcrumb .fileTrail'),
    sliderBtnMain = document.querySelector('.slider-btn-main'),
    menuFeaturedOut = document.getElementById('dropdownMenuButton'),
    menuDivisionsOut = document.querySelector('.d-flex #divisions-dropdown'),
    sliderBtn = document.querySelector('.slider-btn');

    if (hamburger) {
      toggle.addEventListener('click', function(e) {
        e.preventDefault();
        const mobileMenu = document.getElementById('mobile-inner');
        mobileMenu.classList.toggle('active');
        this.classList.toggle('in');
        this.classList.toggle('closed');
      });
    
      hamburger.addEventListener('keyup', (event) => {
        event.preventDefault;

        const key = event.key || event.keyCode;
        console.log(key);

        if (key === 13 || key === 'Enter') {
          if (event.currentTarget.id == 'mobile-menu') {
            hamburger.classList.add('expanded');
          }
        }

        if (event.target.id == 'close') {
          toggle.focus();
        }

        if (event.target.id == 'submit-mobile' && toggle.classList.contains('closed')) {
          if (sliderBtn) {
            sliderBtn.focus();
          }
          if (sliderBtnMain) {
            sliderBtnMain.focus();
          }
          if (breadcrumb) {
            breadcrumb.focus();
          }
        }

        if (event.shiftKey && (key === 9 || key === 'Tab')) {
          if (event.target.id === 'close') {
            if (window.innerWidth >= '768' && window.innerWidth <= '992') {
              toggle.focus();
            }
            else {
              menuFeaturedOut.focus();
            }
          }
        }

        if ((key === 'Escape' || key === 'Esc' || key === 27)) {
          const mobileMenu = document.getElementById('mobile-inner');
          hamburger.classList.remove('expanded');
          toggle.classList.remove('active', 'in');
          toggle.classList.add('closed');
          mobileMenu.classList.remove('active');
          if (toggle.classList.contains('closed')) {
            toggle.focus();
          }
        }
      });
    }
  },
}