export const Footer = {
  init: function() {
    this.PopulateNewsletterField();
  },
  PopulateNewsletterField: () => {
    const urlParams = new URLSearchParams(window.location.search);
    
    const email = urlParams.get('formfields[email]');
    
    if (email) {
      const targetField = document.getElementById('prospect_name');
      if (targetField) {
        targetField.value = email;
      }
    }
  }
}
